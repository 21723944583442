import React, { useEffect, useState } from "react";
import './Reset.css'
import {  Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth,sendPasswordReset} from "../Firebase/Firebase";

const Reset = () => {

  const [email,setEmail] = useState('')
  const navigate = useNavigate();

  const [user] = useAuthState(auth);
  useEffect(() => {
    if (user) {
      navigate("/Home");
    }
  }, [user, navigate]);
  return (
    <div className="reset">
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      <link
        href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css"
        rel="stylesheet"
      />
      <div class="form-gap" style={{ paddingTop: "70px" }}></div>
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-md-offset-4">
            <div class="panel panel-default">
              <div class="panel-body">
                <div class="text-center">
                  <h3>
                    <i class="fa fa-lock fa-4x"></i>
                  </h3>
                  <h2 class="text-center">Forgot Password?</h2>
                  <p>You can reset your password here.</p>
                  
                  <div class="panel-body">
                    <form
                      id="register-form"
                      role="form"
                      autocomplete="off"
                      class="form"
                      method="post"
                    >
                      <div class="form-group">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <i class="glyphicon glyphicon-envelope color-blue"></i>
                          </span>
                          <input
                            id="email"
                            name="email"
                            placeholder="email address"
                            class="form-control"
                            type="email"
                            value={email}
                            onChange={e =>setEmail(e.target.value)}
                            
                            required
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <button
                          name="recover-submit"
                          class="btn btn-lg btn-primary btn-block"
                          value="Reset Password"
                         type="button"
                          onClick={()=> sendPasswordReset(email)}
                        >
                            Send
                            </button>
                            <h1 style={{fontSize:"15px",textTransform:"uppercase"}}>
                              <Link to={'/'}>Login </Link>
                             </h1>
     
                      </div>
                    

                      <input
                        type="hidden"
                        class="hide"
                        name="token"
                        id="token"
                        value=""
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
