import React from 'react'
import './Fuel.css'
const Fuel = () => {
  return (
    <div className='fuel'>
        <span className='heading1'> <h1>FUEL YOUR PURSUIT</h1>  </span>
        <span  className='heading'> <p>It's as mental as it is physical</p> </span>

    <div className='section2'>
    <div class="image-grid">
  <div class="image-row">
    <div class="image image-01"></div>  
    {/* <div class="image image-02"></div>   */}
    <div class="image image-03"></div>  
  </div> 
  <div class="image-row">
    <div class="image image-04"></div>  
    <div class="image image-05"></div>  
  </div>  
  {/* <div class="image-row">
    <div class="image image-06"></div>  
    <div class="image image-07"></div>  
    <div class="image image-08"></div>  
    <div class="image image-09"></div>  
  </div>  
  <div class="image-row">
    <div class="image image-10"></div>  
    <div class="image image-11"></div>  
    <div class="image image-12"></div>  
  </div>  */}
</div>  

    </div>
    </div>
  )
}

export default Fuel