import React, { useEffect, useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Pagination, Navigation, Parallax, EffectFade } from "swiper";
// import "./components/Home.css";
// import "swiper/css";
// import "swiper/css/effect-fade";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
import "./Slide.css";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import Fuel from "./Fuel";


const Slide = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true);
    }, 1000);
    return () => setLoading(false);
  }, []);

  return (
    <div className="Home ">
      {loading ? (
        <div className="swiper">
            {/* <Swiper
            spaceBetween={30}
            effect={"fade"}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            speed={800}
            loop={true}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Parallax, Navigation, Pagination, Autoplay]}
            className="mySwiper"
          > */}
          <MDBCarousel showIndicators showControls fade>
            <MDBCarouselItem
                speed={800}
                loop={true}
              className="w-100 d-block"
              itemId={1}
              src={require('../Image/pro1.jpg')}
              alt="..."
         
            
            >
              <h5>First slide label</h5>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </MDBCarouselItem>

            <MDBCarouselItem
              className="w-100 d-block"
              itemId={2}
              src={require('../Image/pro2.jpg')}
              alt="..."
            >
              <h5>Second slide label</h5>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </MDBCarouselItem>

            <MDBCarouselItem
              className="w-100 d-block"
              itemId={3}
              src={require('../Image/pro3.jpg')}
              alt="..."
            >
              <h5>Third slide label</h5>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p>
            </MDBCarouselItem>
          </MDBCarousel>
         <Fuel/>
        </div>
        
      ) : (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
 
    </div>
  );
};

export default Slide;
