import { signOut } from 'firebase/auth';
import React, { useState,useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import {   useNavigate } from "react-router-dom";
import { auth } from '../Firebase/Firebase';


import {BiLogIn} from 'react-icons/bi';
import {AiOutlineUsergroupAdd} from 'react-icons/ai';
import {AiOutlineShoppingCart} from 'react-icons/ai';
import './Navbar.css'
import Slide from './Slide';


// const [menuOpened, setMenuOpened] = useState(false);
 

const Home = () => {
    const navigate = useNavigate()
 

    const [navbar,setNavbar]=useState(false);
    
  const [user, loading] = useAuthState(auth);
  const [regis] = useAuthState(auth);

  useEffect(() => {
    if (loading) {
      return;

    }
    if (!user){
      navigate('/');
      
    } 
    // if (!regis){
    //   navigate('/register');
    // } 
    
  }, 
  [user, loading , navigate]);

    console.log(user)
    const logout = () => {
        signOut(auth);
        alert('LogOut')
        navigate('/')
      
      };
      const log = () => {
        signOut(auth);
        // alert('Login')
        navigate('/')
      
      };
      console.log(regis)
      const register = () => {
        signOut(auth);
        // alert('Login')
        // navigate('/register')
      
      };
      const changeBackground =()=>{
        if(window.scrollY >=60){
          setNavbar(true)
        }else{
          setNavbar(false)
        }
      }
      window.addEventListener('scroll',changeBackground);
  return (
<div className='Home' >
<div className='not fixed-top '>
<nav className={navbar ? 'navbar active' : 'navbar'} >
      <nav className="navbar navbar-expand-lg py-3  " >
        <div className="container">
    
           <div class="row">
    <div class="col-md-12 text-center">
      <h3 class="animate-charcter"> DELTA INFOX</h3>
    </div>
  </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0  ">
              <li className="nav-item">
                <a className="nav-link text-white active" aria-current="page" href="">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white" href="">
                  Products
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white" href="">
                  Products
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white" href="" >
                  Products
                </a>
              </li>            
            </ul>
           <div className="buttons ">
          <a href="" className="btn btn-outline-dark text-white " onClick={()=>log('/')}>
          <BiLogIn className="me-2  "/>
            Login</a>
            <a href="" className="btn btn-outline-dark ms-2 text-white " onClick={()=>register('/register')}  >
          <AiOutlineUsergroupAdd    />
         
            Register</a>
            <a href="" className="btn btn-outline-dark ms-2 text-white " onClick={()=>navigate('/cart')}>
          <AiOutlineShoppingCart   className="me-2  "/>
            Cart(0)</a>
            <button   className="btn btn-outline-dark ms-2  text-white" onClick={()=>logout()}>Sign Out</button>
            {/* <Link to={'/cart'}> Forgot password?</Link>   */}
           </div>
          </div>
        </div>
      </nav>
      </nav>
    </div>
    
   
    <Slide/>
   
   
</div>

  )
}

export default Home 