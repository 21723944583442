import React from 'react'
import {BrowserRouter as Router , Routes , Route } from 'react-router-dom'
import Create from './Login/Create'
import Login from './Login/Login'
import Home from './Login/Home'
import Reset from './Login/Reset'
import Cart from './Login/Cart'


// import Slide from './Login/Slide'

const App = () => {
  return (
    <Router>
    <Routes>
      <Route exact path='/' element={<Login/>} />
      <Route exact path='/register' element={<Create/>} />
      <Route exact path='/home' element={<Home/>} />
      <Route exact path='/reset' element={<Reset/>} />
      <Route exact path='/cart' element={<Cart/>} />

        

    </Routes>


  </Router>

   
  )
}

export default App